import { LitElement, html, css } from 'lit-element';
import SharedStyles from './shared-styles'
import AuthService from '../../services/auth.service'
const authService = new AuthService();

import './app-side-nav'

// Colors Being Used
// text_gray 300, 400, 200, 500, 900
// bg_gray 100, 600, 700, 800, 900
// text white


export class AppUserElement extends LitElement {
  static get properties() {
    return {
        user: {type:Object},
    }
  }

  constructor() {
    super();
    this.user = {};
  }

  static get styles(){
        return [SharedStyles]
    }

  render() {
    if(!this.user || !this.user.name){
        return html``;
    }
    return html`
        <a href="/profile" class="flex-shrink-0 w-full group block">
            <div class="flex items-center">
            <div>
                <img class="inline-block h-9 w-9 rounded-full" src="${this.user.picture}" alt="">
            </div>
            <div class="ml-3">
                <p class="text-sm font-medium text-white">
                ${this.user.name}
                </p>
                <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                 View profile
                </p>
            </div>
            </div>
        </a>
    `
  }
}


window.customElements.define('app-user-element', AppUserElement);

