import { LitElement, html, css } from "lit-element";
import SharedStyles from "../components/core/shared-styles";
import "../components/stack-list/stack-list";
import "../components/stack-list/stack-item";
import "../components/map-area/map-area";
import "../components/modal-dialog/modal-dialog";
import AuthService from "../services/auth.service";
const authService = new AuthService();
const { GOOGLE_MAPS_API_KEY = "" } = window._env_;
export class ProfileView extends LitElement {
  static get properties() {
    return {
      isAuthorized: { type: Boolean },
      account: { type: Object },
      isEditEnabled: { type: Boolean },
      expanded: { type: Boolean },
      properties: { type: Array },
      property: { type: Object },
      dialogVisible: { type: Boolean },
      token:{ type: String}
    };
  }

  constructor() {
    super();
    this.account = {};
    this.isEditEnabled = true;
    this.expanded = false;
    this.providers = [];
    this.provider = {};
    this.dialogVisible = false;
  }

  static get styles() {
    return [
      SharedStyles,
      css`
        .grid-container {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 2rem;
          margin-left: 5%;
          margin-right: 5%;
          margin-top: 2.5%;
          border-color: #d1d5db;
          border-style: solid;
          border-width: 1px;
          border-radius: 10px;
        }
        .subtitle {
          color: #374151;
        }
        .list-container {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          margin-left: 5%;
          margin-right: 5%;
          margin-top: 2.5%;
          border-color: #d1d5db;
          border-style: solid;
          border-width: 1px;
          border-radius: 10px;
        }

        .settings-header {
          display: block;
          max-width: 100%;
        }
      `,
    ];
  }

  async firstUpdated() {
    this.isAuthorized = await authService.isAuthorized();
    if (this.isAuthorized) {
      this.account = await authService.getUser().then((user) => {
        return user;
      });
    }
  }

  
  profile() {
    return [
      {
        id: `name`,
        label: "Name",
        name: "FullName",
        type: "text",
        required: true,
        class: `mt-1 w-full border-none`,
        value: `${this.account.name}`,
        disabled: true,
      },
      {
        id: `email`,
        label: "Email",
        name: "email",
        type: "email",
        class: `mt-1 w-full border-none`,
        required: false,
        disabled: true,
        value: `${this.account.email}`,
      },
    ].map((data, index) => {
      return html`
      <div
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
          >
            <dt
              class="text-sm leading-5 font-medium text-gray-500"
            >
              ${data.label}
            </dt>
            <dd
              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
            >
              ${data.value}
            </dd>
          </div>`;
    })
  }

  render() {
    const fields = this.profile();
    return html`
      <div class="pt-2 pb-6 md:py-6">
        ${this.dialogVisible ? this.dialogModal() : ""}
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">Profile</h1>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div class="py-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                  Profile details.
                </p>
              </div>
              <div class="px-4 py-5 sm:p-0">
                <dl>
                  ${fields}
                </dl>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    `;
  }
}

window.customElements.define("profile-view", ProfileView);
