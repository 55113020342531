import { LitElement, html, css } from 'lit-element';
import { radiusToPolygon } from './polygon'
import SharedStyles from '../core/shared-styles'

/**
 * @customElement
 * @polymer
 */
export class MapArea extends LitElement {
  static get properties() { 
    return { 
      apiKey:     { type: String, attribute: 'api-key' },
      coordinates:{ type: Array  },
      address:    { type:String },
      radius:     { type: Number },
      details:    { type: Object },
      zoom:       { type: Number }
    };
  }
  static get styles(){
    return [SharedStyles,css`  
    :host {}
`]
}
  constructor() {
    super()
    this.apiKey      = null;
    this.coordinates = [];
    this.radius      = null
    this.details     = {}
    this.zoom        = 15;
  }

  draw(){
    let circle = 
      radiusToPolygon(this.coordinates, this.radius)
        .coordinates[0]
        .map((coordinate) => coordinate.reverse().join(","))
        .join("|")
      return circle
  }
  
  attributeChangedCallback(name, oval, val){
    console.log(val)
    switch(name) {
        case 'api-key': 
         this.apiKey = val
         break;
        case 'zoom':
        case 'radius':
          this.radius = parseInt(val) * 1600
          break;
        case 'coordinates': 
          this.coordinates = JSON.parse(val)
          break;
        case 'address': 
          this.address = val
          break;  
        case 'details': 
          this.details = JSON.parse(val)
          break;
    }
  }

  staticMap(){
    let path = `color:0x102336|weight:5|fillcolor:0xB3CBE4|${this.draw()}`
     console.log(window.encodeURI(this.address));
     const  coordinates = this.coordinates || []
     const  format      = this.address ? encodeURI(this.address) : coordinates.reverse().join(",")
     const uri = `https://maps.googleapis.com/maps/api/staticmap?center=${format}&`
     +`zoom=${this.zoom}&`
     +`size=2048x2048`
     +`&markers=color:0x5D88BB|${format}&`
     +`key=${this.apiKey}&`
     +`format=png&`
     +`maptype=roadmap&`
     /*+`style=element:geometry|color:0xf5f5f5&`
     +`style=element:labels.icon|visibility:off&`
     +`style=element:labels.text.fill|color:0x616161&`
     +`style=element:labels.text.stroke|color:0xf5f5f5&`
     +`style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&`
     +`style=feature:poi|element:geometry|color:0xeeeeee&`
     +`style=feature:poi|element:labels.text.fill|color:0x757575&`
     +`style=feature:poi.park|element:geometry|color:0xe5e5e5&`
     +`style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&`
     +`style=feature:road|element:geometry|color:0xffffff&`
     +`style=feature:road.arterial|element:labels.text.fill|color:0x757575&`
     +`style=feature:road.highway|element:geometry|color:0xdadada&`
     +`style=feature:road.highway|element:labels.text.fill|color:0x616161&`
     +`style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&`
     +`style=feature:transit.line|element:geometry|color:0xe5e5e5&`
     +`style=feature:transit.station|element:geometry|color:0xeeeeee&`
     +`style=feature:water|element:geometry|color:0xc9c9c9&`
     +`style=feature:water|element:labels.text.fill|color:0x9e9e9e&`*/
     +`path=${path}&`
     +`map_id=37c3a8c7a6fad4e8`
     return uri
  }

  connectedCallback(){
    super.connectedCallback()
  }
  
  render() {
    return html`
    <div  class="mt-10 grid-container">
    <div class="card-content">
    <div class="tile is-parent"> 
      <div class="content tile is-parent">
      <div class="grid-list">
        <img src="${this.staticMap()}" class="image w-max">
        </img>
      </div>
      </div>
    </div>
    </div> 
    </div>
    `
  }
}
customElements.define('map-area', MapArea);

