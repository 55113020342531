import { LitElement, html, css } from 'lit-element';
import SharedStyles from '../core/shared-styles'
export class StackItem extends LitElement {
    static get properties() {
      return {
        expanded:{type: Boolean}
      }
    }
  
    constructor() {
      super();
      this.expanded = false;
    }
  
    static get styles(){
        return [SharedStyles,css`  
        :host {}
    `]
    }
  
    
    render() {
        return html`
        <div class="block hover:bg-gray-50">
        <div class="px-4 py-4 flex items-center sm:px-6">
          <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
           <slot name="description">
             <div>Description</div>
           </slot>
            <div class="mt-4 flex-shrink-0 sm:mt-0">
              <div class="flex overflow-hidden">
                <slot name="action">
                  <button 
                    @click="${(_) => { this.expanded = !this.expanded }}"
                    class="border border-transparent mr-5 rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:bg-primary"
                  >
                  Connected
                  &nbsp;
                  <svg 
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-white-400"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path 
                      stroke-linecap="round" 
                      stroke-linejoin="round" 
                      stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" 
                    />
                  </svg>
                 </button>
                </slot>
                 <slot class="ml-5"></slot>
              </div>
            </div>
          </div>
        </div>
       </div>
       <slot name="details" .hidden="${!this.expanded}">
        <div>Your data</div>
       </slot>
         `
    }
  }
  
  
  window.customElements.define('stack-item', StackItem);
  
  
  
  