import {Router} from '@vaadin/router';
import AuthService from '../services/auth.service';
const authService = new AuthService();


// const actions = {
//     secured: 
//     default: (context, commands, config)=>{},

// }






const isAuthorized = async (context, commands) => {
    const authenticated = await authService.isAuthorized();
    if(authenticated){
      console.log('user is authenticated', authenticated)
      authService.getUser().then(console.log)
      context.next();
    }
    else{
      authService.login();
    }
  }

const callbackHandler = async (context, commands) => {
    console.log('context',context); //context.search(contains code)
    const { search = '', params = {}} = context
    const { path:[callback_type=undefined]=[] } = params;
    console.log('path', callback_type, search)
    switch(callback_type){
      case 'auth': {
        return authService.handleCallback(context, commands)
        break;
      }
    }

  }

  const nav_routes = [
    // {
    //     name:'',
    //     icon:'',
    //     component: '',
    //     path: '',
    //     action: ''
    // },
    {
        name: 'Profile',
        icon: 'user',
        path: '/profile', 
        component: 'profile-view',
        visiblity: 'SECURE',
        type: 'link',
        action: async(context, command) => {
            const authenticated = await authService.isAuthorized();
            if(!authenticated){
                authService.login();
            }
            await import('../views/profile');
        },
    },
    {
        name: 'Log Out',
        icon: 'logout',
        path: '/logout', 
        action: async (context,command)=>{
            await authService.logout()
        },
        component: 'loading-view', 
        visiblity: 'SECURED',
        type: 'function'
    },
    {
        name: 'Log In',
        icon: 'login',
        path: '/login', 
        action: async (context, command)=>{
            await authService.login()
            // const res = await authService.register()
            // console.log(res)
        },
        component: 'loading-view', 
        visiblity: 'PUBLIC',
        type: 'function'
    },
    {
        name: 'Sign Up',
        icon: 'user',
        path: '/signup', 
        component: 'register-view',
        visiblity: 'PUBLIC',
        type: 'link'
    },
]

const routes = [
    {
        path: '/callback/:path*',
        action: callbackHandler,
        component: 'loading-view', 
    },
    {
        name: 'Scheduler',
        icon: 'scheduler',
        path: '/advisor/:token/scheduler', 
        component: 'scheduler-view',
        visiblity: 'ALL',
        type: 'link',
        action: async() => {
            const authenticated = await authService.isAuthorized();
            if(!authenticated){
                authService.login();
            }
            await import('../views/scheduler');
        },
    },
    {
        path: '/',
        // component: 'loading-view',
        action: async (context, commands) => {
                return commands.redirect('/profile');
          }
    }
]


export const getRoutes = async (user)=>{
    const all_routes = [...nav_routes, ...routes].filter(route=>true)//TODO: implement auth filtering here

    return all_routes;
}

export const getNavItems = async (user)=>{
    const authenticated = await authService.isAuthorized();
    // const authenticated = false
    const all_routes = [...nav_routes].filter(route=>{
        switch(route.visiblity) {
            case 'SECURED':
              return authenticated
            case 'PUBLIC':
                return !authenticated
            case 'ALL':
                return true;
            default:
                return false;
          }
    }).map((route,index)=>{
        if(route.type==='link'){
            route.click = ()=>Router.go(route.path);
        }
        else if(route.type==='function'){
            route.click = route.action;
        }
        route.index = index;
        return route
    })
    return all_routes;
}
