
import * as RequestService from './request.service';
const { API_HOST='' } = window._env_;
export default class AdvisorService { 
    constructor(uri=API_HOST) {
        this.uri = uri
    }

    async getAdvisors(account) {
        return await RequestService._read({uri: `${this.uri}/${account}`})
    }

    async getGetAdvisor(id) {
        return await RequestService._read({uri: `${this.uri}/${id}`})
    }

    async getAdvisors(token) {
        return RequestService._stream({uri: `${this.uri}/v1/advisors?name=accessToken.accessToken&value=${token}`})
    }

}

