

import { LitElement, html, css } from 'lit-element';
import SharedStyles from '../components/core/shared-styles'
import "../components/stack-list/stack-list";
import "../components/stack-list/stack-item";
import AdvisorService from '../services/advisor.service';

const service = new AdvisorService()

export class SchedulerView extends LitElement {
  static get properties() {
    return {
      advisors: {type: Array}
    }
  }

  constructor() {
    super();
    this.advisors = [];
    this.advisor = {};
  }

  static get styles(){
      return [SharedStyles,css`
        :root {
            height: 100%
        }
      `]
  }

  async firstUpdated(){
    const path    = location.pathname.split("/")
    const token   = path[path.length -2]
    this.advisors = await service.getAdvisors(token)
  }

  openCalender(){
    const event = new CustomEvent('open-calendar', { bubbles: true})
    window.dispatchEvent(event);
    console.log("dispatching event, {}", event)
  }

  advisorList(){
    return this.advisors.map((advisor, i) => {
      return advisor ? html`
      <stack-item>
          <div slot="description">
            <div class="flex text-sm font-medium">
              <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                <div class="min-w-0 flex-1 flex items-center">
                  <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                     <div>
                       <p class="mt-2 flex items-center text-sm text-purple-600 truncate">
                       <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg> 
                <span class="truncate">${advisor.name}</span>
                      </p>
                       <p class="mt-2 flex items-center text-sm text-gray-500">
                          <!-- Heroicon name: solid/mail -->
                          <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                          <span class="truncate">${advisor.email}</span>
                       </p>
                      </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
            <div slot="action">
            <button 
              type="button" 
              @click="${this.openCalender}"
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
  <!-- Heroicon name: solid/mail -->
                <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                Open Scheduler
            </button>
           </div>
        </stack-item>
      ` 
      : ""
  })
  }

  render() {
    return html`
    <div class="pt-2 pb-6 md:py-6">
        ${this.dialogVisible ? this.dialogModal() : ""}
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">Advisor</h1>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div class="py-4">
            <div class="bg-white overflow-hidden sm:rounded-lg">
              <stack-list class="mt-10 rounded-none" .items="${this.advisorList()}">
              </stack-list>
            </div>
          </div>
    </div>
    `
  }
}


window.customElements.define('scheduler-view', SchedulerView);

