import { LitElement, html, css } from 'lit-element';
import SharedStyles from './shared-styles'
import AuthService from '../../services/auth.service'
const authService = new AuthService();

import './app-side-nav'
import './app-user-element'

export class AppLayout extends LitElement {
  static get properties() {
    return {
        navItems: { type: Array },
        isAuthorized: {type: Boolean},
        user: {type: Object},
        nav_logo: {type:String},
        menuIsOpen: {type:Boolean}
    }
  }

  constructor() {
    super();
    this.navItems     = [];
    this.isAuthorized = false;
    this.user = {};
    this.nav_logo = ''
    this.menuIsOpen = false
  }

  async firstUpdated(){
    this.isAuthorized = await authService.isAuthorized();
    if(this.isAuthorized){
        this.user = await authService.getUser();
    }
  }

  static get styles(){
      return [SharedStyles]
  }

  toggleMenu(){
      this.menuIsOpen = !this.menuIsOpen;
  }

    getNavLogo(logo){
        return html`
            <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-12 w-auto" src="${this.nav_logo}" alt="SimplyAdvised"> 
            </div>
        `
    }

    getProfile(isAuthorized, user) {
        console.log(isAuthorized)
       return isAuthorized ? html`<app-user-element .user=${user}></app-user-element>`  :  html ``
        
    }
    

  render() {
    return html`

    <div class="h-screen flex overflow-hidden bg-gray-100">
        <div class="md:hidden">
            <div class="fixed inset-0 flex  transition-opacity ease-linear duration-300 ${this.menuIsOpen ? 'opacity-100 z-40' :'opacity-0 z-0' }">
                <div class="fixed inset-0 transition-opacity ease-linear duration-300 ${this.menuIsOpen ? 'opacity-100' :'opacity-0' }">
                    <div class="absolute inset-0 bg-gray-600 opacity-75 "></div>
                </div>
                <div class="relative flex-1 flex flex-col max-w-xs w-full bg-secondary transition ease-in-out duration-300 transform ${this.menuIsOpen ? 'translate-x-0' : '-translate-x-full'}">
                    <div class="absolute top-0 right-0 -mr-12 pt-2">
                        <!-- Close Menu Button -->
                        <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ${this.menuIsOpen ? '' : 'hidden'}" @click="${this.toggleMenu}">
                            <span class="sr-only" >Close sidebar</span>
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                        ${this.getNavLogo(this.nav_logo)}
                        <app-side-nav .navItems="${this.navItems}" .nav_logo=${this.nav_logo}></app-side-nav> 
                    </div>
                    <div class="flex-shrink-0 flex bg-secondary-dark p-4">
                        <app-user-element .user=${this.user}></app-user-element>
                    </div>
                </div>
                <div class="flex-shrink-0 w-14">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>
        <!-- Desktop Side Nav -->
        <div class="hidden md:flex md:flex-shrink-0">
            <div class="flex flex-col w-64">
                <div class="flex flex-col h-full flex-1 bg-secondary">
                    <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        ${this.getNavLogo(this.nav_logo)}
                        <app-side-nav .navItems="${this.navItems}" .nav_logo=${this.nav_logo}></app-side-nav> 
                    </div>
                    <div class="flex-shrink-0 flex bg-secondary-dark p-4">
                        ${this.getProfile(this.isAuthorized,this.user)}
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Main Window Wrapper -->
        <div class="flex flex-col w-0 z-10 flex-1 overflow-hidden">
            <!-- Open Menu Button -->
            <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-secondary hover:text-seconday-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary" @click="${this.toggleMenu}">
                <span class="sr-only">Open sidebar</span>
                <!-- Heroicon name: menu -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
            </div>
            <main class="flex-1 relative z-10 overflow-y-auto focus:outline-none" tabindex="0">
                <div class="py-6">
                    <slot name="content"></slot>
                </div>
            </main>
        </div>
    </div>

    `
  }
}


window.customElements.define('app-layout', AppLayout);

