import AuthService from './auth.service';
const authService = new AuthService();
export const _created = async (options = {}, secured = true) => {
    const accessToken = await authService.getToken();

    const { uri = '', method='POST', body = {}, headers = {}} = options
    return fetch(uri,{
        method: method, 
        mode: 'cors', 
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
            ...headers,
            Authorization: secured ? `Bearer ${accessToken}` : ''
        },
        body : typeof body === 'object' ? JSON.stringify(body) : body,
      })
      .then(async (response) => {
          if(response.ok){
            return await response.json()
          }
          return Promise.reject({status: response.status, error: response.statusText})
      })
}

export const _read = async (options = {}, secured = true) => {
  const accessToken = await authService.getToken();

  const { uri = '', method='GET', headers = {}} = options
  return fetch(uri,{
      method: method,
      mode: 'cors', 
      credentials: "include",
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: secured ? `Bearer ${accessToken}` : '',
        ...headers,
      }),
    })
    .then(async (response) => {
        if(response.ok){
          const json = await response.json()
          return json
        }
        return Promise.reject({status: response.status, error: response.statusText})
    })
}

export const _stream = async (options = {}, secured = true) => {
  const accessToken = await authService.getToken();
  const { uri = '', method='GET', headers = {}} = options
  const utf8Decoder = new TextDecoder("utf-8");
  return fetch(uri,{
      method: method,
      mode: 'cors', 
      credentials: "include",
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: secured ? `Bearer ${accessToken}` : '',
        ...headers,
      }),
    })
    .then(async (response) => {
        if(response.ok){
         const reader = response.body.getReader()
         let result = ''
         while (true) {
          const { done, value } = await reader.read();
          console.log(utf8Decoder.decode(value))
          if (done) {
            break;
          }
          result += utf8Decoder.decode(value)
        }
        console.log(JSON.parse(result))
        return  JSON.parse(result);
      }
      return Promise.reject({status: response.status, error: response.statusText})
    })
}

export const _update = async (options = {}, secured = true) => {
    const accessToken = await authService.getToken();
  
    const { uri = '', method='PUT', headers = {}} = options
    return fetch(uri,{
        method: method, 
        headers: {
          'Content-Type': 'application/json',
            ...headers,
            Authorization: secured ? `Bearer ${accessToken}` : ''
  
        },
        body: typeof body === 'object' ? JSON.stringify(body) : body,
      })
      .then((response) => {
          if(response.ok){
            return response.json()
          }
          return Promise.reject({status: response.status, error: response.statusText})
      })
  }

  export const _delete = async (options = {}, secured = true) => {
    const accessToken = await authService.getToken();
  
    const { uri = '', method='DELETE', headers = {}} = options
    return fetch(uri,{
        method: method, 
        headers: {
          'Content-Type': 'application/json',
            ...headers,
            Authorization: secured ? `Bearer ${accessToken}` : ''
  
        },
      })
      .then((response) => {
          if(response.ok){
            return response.json()
          }
          return Promise.reject({status: response.status, error: response.statusText})
      })
  }
