/**
 * Courtesy of https://github.com/gabzim/circle-to-polygon
 **/ 
const toRadians = (angleInDegrees) => {
  return angleInDegrees * Math.PI / 180;
}

const toDegrees = (angleInRadians) => {
  return angleInRadians * 180 / Math.PI;
}

const offset = (c1, distance, bearing) => {
  let lat1 = toRadians(c1[1]);
  let lon1 = toRadians(c1[0]);
  let dByR = distance / 6378137; // distance in meters divided by 6378137 (radius of the earth) wgs84
  let lat = Math.asin(
    Math.sin(lat1) * Math.cos(dByR) +
    Math.cos(lat1) * Math.sin(dByR) * Math.cos(bearing));
  let lon = lon1 + Math.atan2(
      Math.sin(bearing) * Math.sin(dByR) * Math.cos(lat1),
      Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat));
  return [toDegrees(lon), toDegrees(lat)];
}

export const radiusToPolygon = (center, radius, numberOfSegments) => {
  let n = numberOfSegments ? numberOfSegments : 32;
  let flatCoordinates = [];
  let coordinates = [];
  for (let i = 0; i < n; ++i) {
    flatCoordinates.push.apply(flatCoordinates, offset(center, radius, 2 * Math.PI * i / n));
  }
  
  flatCoordinates.push(flatCoordinates[0], flatCoordinates[1]);
  
  for (let i = 0, j = 0; j < flatCoordinates.length; j += 2) {
    coordinates[i++] = flatCoordinates.slice(j, j + 2);
  }
  
  return {
    type: 'Polygon',
    coordinates: [coordinates.reverse()]
  };
};