import { LitElement, html, css } from 'lit-element';
import SharedStyles from './shared-styles'

import './app-icons'



export class AppSideNav extends LitElement {
  static get properties() {
    return {
        navItems: { type: Array },
        path_name: {type:String}
    }
  }

  constructor() {
    super();
    this.navItems = [];
    this.path_name = '';
  }

  async firstUpdated(){
    window.addEventListener('vaadin-router-location-changed', (event) => {
        const {detail} = event;
        const route_name = detail.location && detail.location.route && detail.location.route.name
        this.path_name = route_name;
      });
  }

  static get styles(){
      return [SharedStyles]
  }

  getNavLinks(items, path_name){
      return items.map(item=>{
        const {name, click, icon, path} = item;
        console.log(path_name, name, path);
        const color = path_name == name ? 'bg-secondary-light text-white' : 'text-gray-300 hover:bg-secondary-light'
        const iconClass=`${path_name == name ? 'text-white' : 'text-gray-300 hover:text-white'} mr-3 h-6 w-6`;
          return html`
            <div class="${color} hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md" @click="${click}">
                <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                <app-icon .name="${icon}" .iconClass="${iconClass}"></app-icon>
                ${name}
      </div>
          `
      })
  }

  render() {
    return html`
        <style>
            :host{
                height:100%;
            }
        </style>
        <!-- Sidebar component, swap this element with another sidebar if you like -->

            
            <nav class="mt-5 flex-1 px-2 bg-transparent space-y-1">
                ${this.getNavLinks(this.navItems, this.path_name)}
            </nav>

    `
  }
}


window.customElements.define('app-side-nav', AppSideNav);

