import createAuth0Client from '@auth0/auth0-spa-js';


const { AUTH0_DOMAIN='', AUTH0_CLIENT_ID='', AUTH0_AUDIENCE='', AUTH_STORAGE_KEY='' } = window._env_;

export default class AuthService{
    constructor(auth_config = null){
        this.auth0 = null;
        this.initialized = false;
        this.auth_config = auth_config || {
            "domain": AUTH0_DOMAIN,
            "client_id": AUTH0_CLIENT_ID,
            "redirect_uri": `${window.location.origin}/callback/auth`,
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
            audience: AUTH0_AUDIENCE
          }
    }

    async init(){
        if(!this.initialized){
            this.auth0 = await createAuth0Client(this.auth_config)
            this.initialized = true;
            return this.auth0
        }
        return 
    }

    async isAuthorized(){
        await this.init()
        return this.auth0.isAuthenticated()
    }

    async getUser(){
        await this.init()
        return this.auth0.getUser()
    }

    async getToken(){
        await this.init()
        const accessToken = await this.auth0.getTokenSilently();
        return accessToken
    }

    async login(){
        await this.init()
        //TODO: attempt silent login for SSO experience
        //await auth0.getTokenSilently(); 
        await this.auth0.loginWithRedirect({
            appState: { location:window.location.href }
          });
    }

    async logout(){
        await this.init()
        await this.auth0.logout({
            returnTo: window.location.origin
        })
        return 
    }

    async handleCallback(context, commands){
        try{
            await this.init()
            const {appState={}} = await this.auth0.handleRedirectCallback()
            const {location = '/', ...state} = appState;
            let previous_path = location.replace(window.location.origin, '') 
            if(previous_path.includes('/callback/auth') || previous_path.includes('/home') || previous_path.includes('/dashboard') || previous_path.includes('/login') || previous_path.includes('/signup')){
                previous_path = '/'
            }
            this.AuthStatusChanged()
            return commands.redirect(previous_path)
        }catch(err){
            console.log(err)
            return commands.redirect('/')
        }
        // // if(redirectFn){ //TODO: implement this.
        // //     return redirectFn('/')
        // // }else{
        //     return window.location.replace(previous_path)
        // // }
    }

    AuthStatusChanged(){
        const event = new CustomEvent("auth-status-changed", {
            detail: {},
            bubbles: true,
            composed: true,
            cancelable: true,
        });
        window.dispatchEvent(event)
    }


    async register(RegistrationObject = {}){
        const body = {
            client_id: AUTH0_CLIENT_ID,
            connection: 'Username-Password-Authentication',
            name: `${RegistrationObject.given_name} ${RegistrationObject.family_name}`,
            user_metadata: { phone_number: RegistrationObject.phone_number },
            ...RegistrationObject
        }
        const uri = `https://${AUTH0_DOMAIN}/dbconnections/signup`
        const method = 'POST'
        return fetch(uri,{
            method: method, 
            headers: {
              'Content-Type': 'application/json',    
            },
            body : typeof body === 'object' ? JSON.stringify(body) : body,
          })
          .then((response) => {
              console.log(response, response.json)
              if(response.ok){
                return response.json()
              }
              try{
                  return response.json().then(body=>{
                      return Promise.reject({status: response.status, error: response.statusText, body: body})
                  })
              }
              catch(err){
                return Promise.reject({status: response.status, error: response.statusText, response})
              }
              
          })

    }
}