import { LitElement, html, css } from 'lit-element';
import {Router} from '@vaadin/router';

import * as RouteService from './routing/routing';

import './components/core/app-layout';

// Immport Views
import './views/scheduler'
import './views/profile'
import './views/loading'

import AuthService from './services/auth.service';

const authService = new AuthService();

const { NAV_LOGO } = window._env_;

export class AdvisorApp extends LitElement {
    static get properties() {
        return {
          theme: {type: String},
          location: {type: Object},
          navItems: { type: Array },
          selected_name: {type:Number},
          isAuthorized: {type: Boolean},
          user: {type: Object}
        };
      }

      constructor() {
        super();
        this.navItems = [];
        this.theme = window.MyAppGlobals.lumo_theme || "light";
        this.selected_name = '';
        this.user = {};
        this.isAuthorized = false;
      }

      async firstUpdated(){
        this.setUser();
        window.addEventListener('vaadin-router-location-changed', (event) => {
          const {detail} = event;
          const route_name = detail.location && detail.location.route && detail.location.route.name
          this.selected_name = route_name;
          console.log('Route Changed: ', this.selected_name)
        });
    
        const outlet = this.querySelector('#app-window') || this.shadowRoot.querySelector('#app-window')
        const router = new Router(outlet);
        const routes = await RouteService.getRoutes()
        router.setRoutes(routes);
        this.setNavigation();
        window.addEventListener('auth-status-changed',()=>{
          this.setNavigation();
          this.setUser();
        })
      }

    async setUser(){
      this.isAuthorized = await authService.isAuthorized();
      if (this.isAuthorized) {
        const user    = await authService.getUser();
        const token   = await authService.getToken();
        user.token = token;
        this.user  = user;      
        window._env_.isAuthorized = this.isAuthorized;
        window._env_.user = this.user;
      }
    } 

    setNavigation(){
      RouteService.getNavItems().then(nav_items=>{
        this.navItems = nav_items
      })
    }



    // static get styles() {
    //   return css`
    //    :root {

    //     --color-primary-light: rgba(44,153,76,1);
    //     --color-primary: rgba(44,153,76,1);
    //     --color-primary-dark: rgba(86,173,112,1);

    //     --color-secondary-light: rgba(81,98,104,1);
    //     --color-secondary: rgba(62,80,87,1);
    //     --color-secondary-dark: rgba(50,64,70,1);

    //     --color-tertiary-light: 
    //     --color-tertiary: 
    //     --color-tertiary-dark: 

    //    }
    //   `;
    // }

  calendar(){
    const event = new CustomEvent('open-calendar', { bubbles: true})
    this.dispatchEvent(event);
    console.log("dispatching event open-calendar, {}", event)
  }  

  render() {
    return html`
        <style>
          :host {

          --color-primary-light: rgba(3, 90, 166, 1);
          --color-primary: rgba(0, 122, 255, 1);
          --color-primary-dark: rgba(18, 1, 54, 1);
          --color-secondary-light: rgba(3, 90, 166, 1);
          --color-secondary: rgba(255,255, 255, 1);
          --color-secondary-dark: rgba(18, 1, 54, 1);

          /* --color-tertiary-light: 
          --color-tertiary: 
          --color-tertiary-dark:  */

          }
        </style>
        <app-layout .navItems="${this.navItems}" .nav_logo=${NAV_LOGO} .isAuthorized="${this.isAuthorized}" .user="${this.user}">
            <!-- <app-navigation slot="navigation"></app-navigation> -->
            
            <div id="app-window" slot="content">
                <!-- <home-view slot="content"></home-view> -->
              </div>
        </app-layout>
    `
  }
}


window.customElements.define('advisor-app', AdvisorApp);

