
import { LitElement, html, css } from 'lit-element';
import SharedStyles from '../core/shared-styles'
import "./stack-item"

export class StackList extends LitElement {
    static get properties() {
      return {
        items:{ type:Array }
      }
    }
  
    constructor() {
      super();
      this.items  = []
    }
  
    static get styles(){
        return [SharedStyles,css`  
        .settings-header {
          display: block;
          max-width: 100%;
        }`]
    }
  
    
    render() {
        return html` 
        <div class="bg-white border border-gray-300 overflow-hidden rounded-md">
             <ul class="divide-y divide-gray-300">
             ${ this.items.map((item) => {
              return html`
                 <li class="px-6 py-4">
                 ${item}
                </li>
               `
             } ) }
             </ul>
        </div>
      `
    }
  }
  window.customElements.define('stack-list', StackList);
  
  
  
  