import { LitElement, html, css } from 'lit-element';
import SharedStyles from '../core/shared-styles'


export class ModalDialog extends LitElement {
    static get properties() {
        return {
          dialogVisible: { type: Boolean }
        };
    }

    constructor() {
      super();
      this.dialogVisible = false;
      this.addEventListener('modal-visibility-change', (e) => {
        console.log(e)
        this.dialogVisible = e.detail.visiable
      });
    }
  
    static get styles(){
        return [SharedStyles,css`  
        .settings-header {
          display: block;
          max-width: 100%;
        }`]
    }
  
    
    render() {
            return html` <div class="fixed z-10 inset-0 overflow-y-auto">
              <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              >
                <div
                  class="fixed inset-0 transition-opacity"
                  aria-hidden="${!this.dialogVisible}"
                >
                  <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="${!this.dialogVisible}"
                  >&#8203;</span
                >
                <div
                  class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <slot>
                     
                  </slot>
                </div>
              </div>
            </div>`;
          
    }
  }
  window.customElements.define('modal-dialog', ModalDialog);
  
  
  
  